import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'completeViaBusinessLink',
    'completeViaBusinessLinkContainer',
    'customerTypeSelect',
    'businessProfileFieldset',
    'businessProfileInput',
    'businessUserSignupUrl',
  ];

  connect() {
    this.updateState();
  }

  updateState() {
    const selectedCustomerType = this.customerTypeSelectTarget.value;
    const consumerCustomerTypeSelected = selectedCustomerType === 'consumer';

    this.updateBusinessLinkCheckbox(consumerCustomerTypeSelected);
    this.updateBusinessFieldsState(consumerCustomerTypeSelected);
    this.updateRolesFieldsState(consumerCustomerTypeSelected);
  }

  updateBusinessLinkCheckbox(consumerType) {
    if (!this.hasCompleteViaBusinessLinkTarget) {
      return;
    }

    this.completeViaBusinessLinkContainerTarget.hidden = consumerType;
    if (consumerType) {
      this.completeViaBusinessLinkTarget.checked = false;
      this.updateUserFieldsState();
    }
  }

  updateRolesFieldsState(consumerType) {
    this.rolesFields.forEach((input) =>
      input.toggleAttribute('disabled', !consumerType),
    );
  }

  updateBusinessFieldsState(consumerType) {
    this.businessProfileInputTargets.forEach((input) =>
      input.toggleAttribute('disabled', consumerType),
    );
    this.businessProfileFieldsetTarget.hidden = consumerType;
  }

  updateUserFieldsState() {
    this.passwordFields.forEach((element) => {
      element.value = null;
      element.toggleAttribute(
        'disabled',
        this.completeViaBusinessLinkTarget.checked,
      );
    });

    if (this.fullNameField) {
      this.fullNameField.value = null;
      this.fullNameField.toggleAttribute(
        'disabled',
        this.completeViaBusinessLinkTarget.checked,
      );
    }
  }

  copySignupUrl() {
    this.businessUserSignupUrlTarget.focus();
    this.businessUserSignupUrlTarget.select();
    this.businessUserSignupUrlTarget.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(this.businessUserSignupUrlTarget.value);

    show_flash('success', 'Link copied to the clipboard!');
  }

  get passwordFields() {
    return this.element.querySelectorAll(
      '#user_password_field input[type="password"]',
    );
  }

  get rolesFields() {
    return this.element.querySelectorAll(
      '[data-hook="admin_user_form_roles"] input[type="checkbox"]',
    );
  }

  get fullNameField() {
    return this.element.querySelector('#user_full_name_field input');
  }
}
