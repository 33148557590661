/**
 * Makes permanent translation key form fields editable when a new form table row is added, specifically for when editing Option Type Values
 * See https://github.com/solidusio/solidus/blob/ff563e165e65af058ea9c611e263d27c287f1aa9/backend/app/assets/javascripts/spree/backend/admin.js#L58-L82 for more details
 */
window.addEventListener('load', () => {
  Spree.ready(() => {
    $('.spree_add_fields').click(function () {
      const target = $(this).data('target');

      $(`${target} tr:visible:last`)
        .find('input:disabled')
        .each(function () {
          const el = $(this);
          el.prop('disabled', false);
        });
    });
  });
});
