import { Controller } from '@hotwired/stimulus';
import singularize from '../../utils/singularize';

export default class extends Controller {
  static targets = ['bannerText', 'selectAll'];
  static values = {
    translationsScope: String,
    bannerTextTranslation: String,
    bannerTextWithCountTranslation: String,
    bannerTextAllWithCountTranslation: String,
    selectAllWithCountTranslation: String,
  };
  static outlets = ['checkbox-list'];

  connect() {
    this.updateCheckedCount(0, false, 0);
    this.checkboxListOutlet.subscribe((event) => {
      if ('checkedCountChanged' in event) {
        this.updateCheckedCount(
          event['checkedCountChanged'],
          event['allItemsChecked'],
          event['totalItems'],
        );
      }
    });
  }

  selectAll() {
    this.checkboxListOutlet.checkAll();
  }

  updateCheckedCount(count, allItemsChecked, totalItems) {
    if (count == 0) {
      this.element.classList.add('display-none');
    } else if (allItemsChecked) {
      this.element.classList.remove('display-none');
      this.bannerTextTarget.textContent = Spree.t_sub(
        this.bannerTextAllWithCountTranslationValue,
        {
          scope: this.translationsScopeValue,
          count: count,
        },
      );

      this.selectAllTarget.textContent = '';
    } else {
      this.element.classList.remove('display-none');

      let translation = Spree.t_sub(this.bannerTextWithCountTranslationValue, {
        scope: this.translationsScopeValue,
        count: count,
      });

      if (count == 1) {
        translation = singularize(translation);
      }

      this.bannerTextTarget.textContent = translation;

      this.selectAllTarget.textContent = Spree.t_sub(
        this.selectAllWithCountTranslationValue,
        {
          scope: this.translationsScopeValue,
          count: totalItems,
        },
      );
    }
  }
}
