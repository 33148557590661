document.addEventListener('DOMContentLoaded', () => {
  const checkboxes = ['show_stock', 'show_availabilities', 'show_prices'];

  const toggleColumns = (className, displayStyle) => {
    document.querySelectorAll(`.${className}`).forEach((column) => {
      column.style.display = displayStyle;
    });
  };

  const updateStockColspan = () => {
    const stockColumn = document.querySelector('.stock-column');
    const stockLocationCheckboxes = document.querySelectorAll(
      '.stock-location-toggle',
    );
    const checkedCount = Array.from(stockLocationCheckboxes).filter(
      (cb) => cb.checked,
    ).length;

    if (stockColumn) {
      if (checkedCount > 0) {
        stockColumn.setAttribute('colspan', checkedCount);
        stockColumn.style.display = '';
      } else {
        stockColumn.style.display = 'none';
      }
    }
  };

  const toggleStockLocations = (isChecked) => {
    const stockLocationsTrigger = document.querySelector(
      '.stock-locations-trigger',
    );
    if (stockLocationsTrigger) {
      stockLocationsTrigger.disabled = !isChecked;
    }
  };

  const handleCheckboxState = (checkboxId, isChecked) => {
    const className = `${checkboxId.replace('show_', '')}-column`;
    toggleColumns(className, isChecked ? '' : 'none');

    if (checkboxId === 'show_stock') {
      const stockColumn = document.querySelector('.stock-column');
      if (stockColumn) {
        if (!isChecked) {
          stockColumn.style.display = 'none';
        } else {
          updateStockColspan();
        }
      }
      toggleStockLocations(isChecked);
    }
  };

  checkboxes.forEach((checkboxId) => {
    const checkbox = document.getElementById(checkboxId);
    if (checkbox) {
      handleCheckboxState(checkboxId, checkbox.checked);
    }
  });

  document.addEventListener('change', (e) => {
    if (checkboxes.includes(e.target.id)) {
      handleCheckboxState(e.target.id, e.target.checked);
    } else if (e.target.classList.contains('stock-location-toggle')) {
      const stockLocationId = e.target.getAttribute('data-stock-location-id');
      document
        .querySelectorAll(
          `.stock-column[data-stock-location-id='${stockLocationId}']`,
        )
        .forEach((column) => {
          column.style.display = e.target.checked ? '' : 'none';
        });
      updateStockColspan();
    }
  });

  document.addEventListener('click', (e) => {
    if (e.target.closest('.product-availabilities-table')) {
      const row = e.target.closest('tbody tr');
      if (row) {
        row.classList.toggle('highlighted');
      }
    }
  });

  const StockCheckbox = document.getElementById('show_stock');
  if (StockCheckbox) {
    toggleStockLocations(StockCheckbox.checked);
  }

  updateStockColspan();
});
