import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['paymentLinkInput'];

  copyUrl() {
    this.paymentLinkInputTarget.focus();
    this.paymentLinkInputTarget.select();
    this.paymentLinkInputTarget.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(this.paymentLinkInputTarget.value);

    show_flash('success', 'Link copied to the clipboard!');
  }
}
