import './user_select';

// Imported by Solidus

Spree.Views.Order.UserUpdater = Backbone.View.extend({
  initialize(attrs) {
    this.orderNumber = attrs.orderNumber;
    this.orderUserEmail = attrs.orderUserEmail;
    this.orderId = attrs.orderId;
    this.currentUserId = attrs.currentUserId;
    this.userSelectView = new Spree.Views.Order.UserSelect({
      el: this.el,
      placeholder: this.orderUserEmail,
    });
    this.listenTo(this.userSelectView, 'select', this.onSelectUser);
  },

  onSelectUser(customer) {
    const confirmed = window.confirm(this.confirmMessage(customer));
    if (confirmed) {
      this.updateUser(customer)
        .then(() => {
          // Imported by Solidus

          show_flash(
            'success',
            `Updated the order's user to '${customer.email}'`,
          );

          return this.createOrderNoteForChangedUser(customer);
        })
        .then(() => {
          window.location.reload();
        })
        .fail(() => {
          // Our version of jQuery promise are not compatible with the
          // native Promise. Once we upgrade to jQuery 3.0 we should use
          // .catch instead of .fail
          // https://jquery.com/upgrade-guide/3.0/#breaking-change-and-feature-jquery-deferred-is-now-promises-a-compatible
          // Imported by Solidus

          show_flash(
            'error',
            "Failed to update order's user. Please try again.",
          );
        });
    }
  },

  updateUser(customer) {
    return Spree.ajax({
      url: Spree.pathFor(`api/orders/${this.orderNumber}`),
      type: 'PUT',
      data: {
        id: this.orderNumber,
        override_email: false,
        order: {
          user_id: customer.id,
        },
      },
    }).promise();
  },

  createOrderNoteForChangedUser(customer) {
    return Spree.ajax({
      url: Spree.pathFor(`admin/orders/${this.orderNumber}/notes`),
      type: 'POST',
      datatype: 'json',
      data: {
        note: {
          user_id: this.currentUserId,
          order_id: this.orderId,
          body: `Order user was changed from ${this.orderUserEmail} to ${customer.email}`,
        },
      },
    }).promise();
  },

  confirmMessage(customer) {
    return `Are you sure you want to change the order's user to ${customer.email}? Payment methods will not be copied over, which could affect preorder payments, and this operation could make a B2C order a B2B order (or vice versa), which can have tax implications (especially in the EU) you need to manually evaluate.`;
  },
});
