import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'helpFields'];

  static classes = ['addButton', 'removeButton'];

  static values = {
    addFields: String,
    addButtonLabel: String,
    removeFields: String,
    removeButtonLabel: String,
    active: Boolean,
  };

  connect() {
    this.toggleFields();
    window.dispatchEvent(new CustomEvent('admin-fields-changed'));
  }

  toggleFields() {
    if (this.activeValue) {
      this.helpFieldsTarget.innerHTML = this.addFieldsValue;
      this.changeButtonToRemove();
      this.activeValue = false;
    } else {
      this.helpFieldsTarget.innerHTML = this.removeFieldsValue;
      this.changeButtonToAdd();
      this.activeValue = true;
    }
  }

  changeButtonToAdd() {
    this.buttonTarget.classList.remove(this.removeButtonClass);
    this.buttonTarget.classList.add(this.addButtonClass);
    this.buttonTarget.innerHTML = this.addButtonLabelValue;
  }

  changeButtonToRemove() {
    this.buttonTarget.classList.remove(this.addButtonClass);
    this.buttonTarget.classList.add(this.removeButtonClass);
    this.buttonTarget.innerHTML = this.removeButtonLabelValue;
  }
}
