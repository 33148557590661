import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['skuSelector', 'batchSelector'];

  static values = {
    variantIdDescriptionMap: Object,
    batchIdNameMap: Object,
    editingEnabled: Boolean,
    defaultSku: String,
  };

  skuSelectorTargetConnected(select) {
    if (!this.editingEnabledValue) {
      const { currentVariantId, currentVariantSku } = select.dataset;
      this.setDisabledState(select, currentVariantId, currentVariantSku);
      return;
    }

    const { currentVariantId } = select.dataset;
    this.addSelectOptions(
      select,
      this.variantIdDescriptionMapValue,
      currentVariantId,
    );
  }

  batchSelectorTargetConnected(select) {
    if (!this.editingEnabledValue) {
      const { currentBatchId } = select.dataset;
      const currentBatchName = this.batchIdNameMapValue[currentBatchId];
      this.setDisabledState(select, currentBatchId, currentBatchName);
      return;
    }

    const { currentBatchId } = select.dataset;
    this.addSelectOptions(select, this.batchIdNameMapValue, currentBatchId);
  }

  setDisabledState(select, defaultOptionId, defaultOptionName) {
    const defaultOption = new Option(
      defaultOptionName,
      defaultOptionId,
      null,
      true,
    );
    select.options.add(defaultOption);
    select.disabled = true;
    select.nextElementSibling?.classList.remove('hidden');
  }

  addSelectOptions(select, optionsMap, selectedOption) {
    Object.entries(optionsMap).forEach(([id, description]) => {
      const selected = id.toString() === selectedOption.toString();
      const option = new Option(description, id, null, selected);
      select.options.add(option);
    });
  }
}
