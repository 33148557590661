import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { optionValueIdVariantsMap: Object };

  static targets = ['choiceSelect', 'skusContainer'];

  choiceSelectTargetConnected() {
    this.updateOptionValueSkus();
    $(this.choiceSelectTarget)
      .select2()
      .on('change', () => this.updateOptionValueSkus());
  }

  updateOptionValueSkus() {
    let choiceId = JSON.parse(this.choiceSelectTarget.value).id;
    let variants = this.optionValueIdVariantsMapValue[choiceId];
    this.skusContainerTarget.innerHTML = '';
    variants.forEach((variant) => {
      let skuListItem = this.createSkuListItem(variant);
      this.skusContainerTarget.appendChild(skuListItem);
    });
  }

  createSkuListItem(variant) {
    const a = document.createElement('a');
    a.textContent = variant['sku'];
    a.href = variant['url'];
    a.target = '_blank';
    const li = document.createElement('li');
    li.appendChild(a);
    return li;
  }
}
