import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select', 'dependentSelect'];

  static values = {
    allowedValues: Array,
  };

  connect() {
    this.updateDependentSelectVisibility();
  }

  updateDependentSelectVisibility() {
    const enabled = this.allowedValuesValue.includes(this.selectTarget.value);
    this.dependentSelectTarget.disabled = !enabled;
  }
}
