/**
 * Allow to remove fields when using spree_add_fields
 */
window.addEventListener('load', () => {
  Spree.ready(() => {
    $('.spree_add_fields').click(function () {
      const target = $(this).data('target');

      $(`${target} tr:visible:last .remove-on-new`).empty();
    });
  });
});
