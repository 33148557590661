import './views/order/user_updater';

Spree.ready(() => {
  // Using backbone views like this is an established pattern in
  // Solidus.

  new Spree.Views.Order.UserUpdater({
    el: $('#order_user_update'),
    orderNumber: $('#order_user_update').data('order-number'),
    orderUserEmail: $('#order_user_update').data('order-user-email'),
    orderId: $('#order_user_update').data('order-id'),
    currentUserId: $('#order_user_update').data('current-user-id'),
  });
});
