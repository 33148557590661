/* global VAT_REFUND_REASON_ID */
/* global VAT_REFUND_AMOUNT */
Spree.ready(() => {
  const refundReasonInput = document.getElementById('refund_refund_reason_id');
  if (!refundReasonInput) return;

  const refundAmountInput = document.getElementById('refund_amount');

  refundReasonInput.addEventListener('change', (event) => {
    const refundReasonId = event.target.value;
    if (VAT_REFUND_REASON_ID === refundReasonId) {
      refundAmountInput.setAttribute('value', `${VAT_REFUND_AMOUNT}`);
    }
  });
});
