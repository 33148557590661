import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['inputs'];

  updateDestroyField({ currentTarget }) {
    const { value: currentModelNumber } = currentTarget;
    const destroy = currentModelNumber == null || currentModelNumber === '';

    currentTarget.nextElementSibling.value = destroy;
  }

  addVariantModelNumberInput(e) {
    e.preventDefault();

    const input = this.inputsTargets.pop();
    const newInput = input.cloneNode(true);

    let index = parseInt(
      newInput.firstElementChild.name.match(/\[(\d+)\]/)[1],
      10,
    );
    newInput.firstElementChild.name = newInput.firstElementChild.name.replace(
      /\[\d+\]/g,
      `[${(index += 1)}]`,
    );

    input.parentNode.insertBefore(newInput, input.nextSibling);
  }
}
