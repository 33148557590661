import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form'];

  deleteVariant(event) {
    if (window.confirm('Are you sure?') === true) {
      event.currentTarget.closest('tr').remove();

      this.formTarget.submit();
    }
  }
}
