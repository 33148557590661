const snackbarCss = `
  .snackbar {
    position: fixed;
    top: 20px;
    right: -100%;
    background-color: lightgreen;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transition: right 0.5s ease;
  }
  .error {
    background-color: lightcoral;
  }
`;

const addSnackbarStyleToDOM = () => {
  if (document.getElementById('snackbar-style')) return;

  const style = document.createElement('style');
  style.id = 'snackbar-style';
  style.appendChild(document.createTextNode(snackbarCss));
  document.head.appendChild(style);
};

export default (text, error) => {
  addSnackbarStyleToDOM();
  const snackbar = document.createElement('div');
  snackbar.textContent = text;
  snackbar.className = 'snackbar';
  if (error) snackbar.classList.add('error');

  document.body.appendChild(snackbar);

  setTimeout(() => {
    snackbar.style.right = '20px';
    setTimeout(() => {
      snackbar.style.right = '-100%';
      setTimeout(() => {
        snackbar.remove();
      }, 500);
    }, 3000);
  }, 100);
};
