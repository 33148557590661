import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select', 'idInput', 'typeInput'];

  selectTargetConnected() {
    this.updateAttributes();
    $(this.selectTarget).on('change', () => this.updateAttributes());
  }

  updateAttributes() {
    const selectedOption =
      this.selectTarget.options[this.selectTarget.selectedIndex];
    const { id, type } = JSON.parse(selectedOption.value);

    this.idInputTarget.value = id;
    this.typeInputTarget.value = type;
  }
}
