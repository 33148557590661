import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'checkbox',
    'globalSelect',
    'globalSelectCheckedStyling',
    'globalSelectUncheckedStyling',
    'globalSelectMinusStyling',
  ];

  initialize() {
    this.subscribers = [];
  }

  connect() {
    this.refresh();
  }

  subscribe(callback) {
    this.subscribers.push(callback);
  }

  refresh() {
    let checkedCount = this.countCheckedItems();
    let totalItems = this.totalItems();
    let allItemsChecked = totalItems == checkedCount;

    this.updateGlobalSelectTargetState(checkedCount, allItemsChecked);
    this.publishCheckedCountChanged(checkedCount, allItemsChecked, totalItems);
  }

  checkGlobalSelect() {
    if (this.someCheckboxChecked()) {
      this.uncheckAll();
    } else {
      this.checkAll();
    }
  }

  publishCheckedCountChanged(newCount, allItemsChecked, totalItems) {
    this.subscribers.forEach((subscriber) =>
      subscriber({
        checkedCountChanged: newCount,
        allItemsChecked: allItemsChecked,
        totalItems: totalItems,
      }),
    );
  }

  updateGlobalSelectTargetState(checkedCount, allItemsChecked) {
    if (checkedCount > 0) {
      this.globalSelectTarget.checked = true;

      if (allItemsChecked) {
        this.updateGlobalSelectCheckboxStyle('checked');
      } else {
        this.updateGlobalSelectCheckboxStyle('minus');
      }
    } else {
      this.updateGlobalSelectCheckboxStyle('unchecked');
      this.globalSelectTarget.checked = false;
    }
  }

  countCheckedItems() {
    return document.querySelectorAll('.checkbox-list-checkbox:checked').length;
  }

  totalItems() {
    return this.checkboxTargets.length;
  }

  someCheckboxChecked() {
    return this.countCheckedItems() > 0;
  }

  checkAll() {
    this.checkboxTargets.forEach((checkbox) => (checkbox.checked = true));
    this.refresh();
  }

  uncheckAll() {
    this.checkboxTargets.forEach((checkbox) => (checkbox.checked = false));
    this.refresh();
  }

  updateGlobalSelectCheckboxStyle(style) {
    this.globalSelectCheckedStylingTarget.classList.add('display-none');
    this.globalSelectUncheckedStylingTarget.classList.add('display-none');
    this.globalSelectMinusStylingTarget.classList.add('display-none');

    switch (style) {
      case 'checked':
        this.globalSelectCheckedStylingTarget.classList.remove('display-none');
        break;
      case 'unchecked':
        this.globalSelectUncheckedStylingTarget.classList.remove(
          'display-none',
        );
        break;
      case 'minus':
        this.globalSelectMinusStylingTarget.classList.remove('display-none');
        break;
    }
  }
}
