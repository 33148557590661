import { Controller } from '@hotwired/stimulus';
import singularize from '../../utils/singularize';

export default class extends Controller {
  static targets = ['mainButton'];
  static values = {
    translationsScope: String,
    mainButtonTranslation: String,
    mainButtonWithCountTranslation: String,
    mainButtonAllWithCountTranslation: String,
  };
  static outlets = ['checkbox-list'];

  connect() {
    this.updateCheckedCount(0);
    this.checkboxListOutlet.subscribe((event) => {
      if ('checkedCountChanged' in event) {
        this.updateCheckedCount(
          event['checkedCountChanged'],
          event['allItemsChecked'],
        );
      }
    });
  }

  updateCheckedCount(count, allItemsChecked) {
    if (count == 0) {
      this.mainButtonTarget.value = Spree.t(this.mainButtonTranslationValue, {
        scope: this.translationsScopeValue,
      });
    } else if (allItemsChecked) {
      this.mainButtonTarget.value = Spree.t_sub(
        this.mainButtonAllWithCountTranslationValue,
        {
          scope: this.translationsScopeValue,
          count: count,
        },
      );
    } else {
      let translation = Spree.t_sub(this.mainButtonWithCountTranslationValue, {
        scope: this.translationsScopeValue,
        count: count,
      });

      if (count == 1) {
        translation = singularize(translation);
      }

      this.mainButtonTarget.value = translation;
    }
  }
}
