import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggle', 'list', 'closedIcon', 'openIcon'];

  connect() {
    this.openIconTarget.classList.add('hidden');
    this.listTarget.classList.add('hidden');
  }

  toggle() {
    if (this.listTarget.classList.contains('hidden')) {
      this.listTarget.classList.remove('hidden');
      this.openIconTarget.classList.remove('hidden');
      this.closedIconTarget.classList.add('hidden');
    } else {
      this.listTarget.classList.add('hidden');
      this.closedIconTarget.classList.remove('hidden');
      this.openIconTarget.classList.add('hidden');
    }
  }
}
