import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field', 'selector', 'selectorContainer'];

  connect() {
    $(document).ajaxComplete(this.toggleFields.bind(this));

    this.toggleSelector();
    this.toggleFields();
  }

  toggleFields() {
    this.fieldTargets.forEach((field) => this.toggleField(field));
  }

  toggleField(field) {
    this.toggleSelector();

    field.classList.toggle(
      'hidden',
      field.dataset.locale !== this.selectedLocale(),
    );
  }

  toggleSelector() {
    this.selectorContainerTarget.classList.toggle(
      'hidden',
      !this.localizedFieldsPresent(),
    );
  }

  selectedLocale() {
    return this.selectorTarget.value;
  }

  localizedFieldsPresent() {
    return this.fieldTargets.length > 0;
  }
}
