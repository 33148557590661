import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['activeCheckbox'];

  updateActiveCheckbox(event) {
    if (event.currentTarget.value === 'expansion_bay_system') {
      this.activeCheckboxTarget.checked = true;
      this.activeCheckboxTarget.disabled = true;
    } else {
      this.activeCheckboxTarget.disabled = false;
    }
  }
}
