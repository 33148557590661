import showSnackbar from './show_snackbar';

const SELECTION_STORAGE_KEY = 'select2_copy_ids';

const getBaseInputElement = (select2Element) => {
  const actualElementId = select2Element.id.replace('s2id_', '');
  const result = $(`#${actualElementId}`);
  if (result == null) {
    throw new Error('Select2 base input element not found!');
  }
  return result;
};

const getInputValues = (inputElement) => {
  return $(inputElement)
    .select2('data')
    .map(({ id }) => id);
};

const getCopiedChoiceIds = () => {
  return localStorage.getItem(SELECTION_STORAGE_KEY);
};

const showMessage = (message, error = false) => {
  showSnackbar(message, error);
};

const copyChoices = (event, inputElement) => {
  event.preventDefault();
  const choiceIds = getInputValues(inputElement);
  if (choiceIds == null || choiceIds.length === 0) {
    showMessage('No choices to copy from the selected input', true);
    return;
  }

  const storageString = JSON.stringify(choiceIds);
  localStorage.setItem(SELECTION_STORAGE_KEY, storageString);
  const subject =
    choiceIds.length + (choiceIds.length > 1 ? ' items' : ' item');
  showMessage(`${subject} copied`);
};

const pasteChoices = (event, inputElement) => {
  event.preventDefault();
  const choiceIds = JSON.parse(getCopiedChoiceIds());
  if (choiceIds == null || choiceIds.length === 0) {
    showMessage('No copied choices to paste', true);
    return;
  }

  const selectedIds = getInputValues(inputElement);
  const ids = [...choiceIds, ...selectedIds];
  const uniqueIds = [...new Set(ids)]; // select2 automatically sorts option values with .val() anyway
  inputElement.val(uniqueIds).trigger('change');
  const inputName = inputElement[0].parentElement.children[0].textContent;
  const subject =
    choiceIds.length + (choiceIds.length > 1 ? ' items' : ' item');
  showMessage(`${subject} pasted to "${inputName}"`);
};

const createButton = (text, icon, callback) => {
  const button = document.createElement('a');
  button.innerHTML = `&nbsp;&nbsp;<span><i class="fa fa-${icon}"></i> <u>${text} items</u></span>&nbsp;&nbsp;`;
  button.style.marginBottom = '.7rem';
  button.style.display = 'inline-block';
  button.href = '#';
  button.onclick = callback;
  return button;
};

const addCopyPasteButtons = () => {
  const select2Elements = document.getElementsByClassName(
    'select2-container select2-container-multi',
  );
  Array.from(select2Elements).forEach((select2Element) => {
    const baseInputElement = getBaseInputElement(select2Element);
    if (baseInputElement[0].disabled) return;

    const container = select2Element.parentElement;
    const copyButton = createButton('copy', 'files-o', (event) =>
      copyChoices(event, baseInputElement),
    );
    const pasteButton = createButton('paste', 'clipboard', (event) =>
      pasteChoices(event, baseInputElement),
    );
    const beforeElement = container.querySelector('br') || select2Element;
    const buttonsContainer = document.createElement('span');
    buttonsContainer.style.whiteSpace = 'nowrap';
    buttonsContainer.appendChild(copyButton);
    buttonsContainer.appendChild(pasteButton);
    container.insertBefore(buttonsContainer, beforeElement);
  });
};

window.addEventListener('load', addCopyPasteButtons);
