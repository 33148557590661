import './admin/actiontext';
import './admin/stimulus';

import './admin/add_select2_copy_paste';
import './admin/improve_spree_add_fields';
import './admin/new_price_logic';
import './admin/order_update_user';
import './admin/permanent_translation_key';
import './admin/product_availabilities';
import './admin/vat_refund_logic';
import './utils/translation_with_substitution';
import * as ActionCable from '@rails/actioncable';

Turbo.session.drive = false;

window.Cable = ActionCable;
if (window.App == undefined) {
  window.App = {};
}
App.cable = ActionCable.createConsumer();
