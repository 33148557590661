Spree.ready(() => {
  const configuratorPriceInput = document.getElementById(
    'price_configurator_price',
  );
  if (!configuratorPriceInput) return;

  const priceAmountInput = document.getElementById('price_price');
  const strikethroughAmountInput = document.getElementById(
    'price_strikethrough_amount',
  );
  const strikethroughAmountEnabledInput = document.getElementById(
    'price_strikethrough_amount_enabled',
  );
  const priceCountryIsoInput = document.getElementById('price_country_iso');
  const anyCountryOption = Array.from(priceCountryIsoInput.options).find(
    (option) => option.label === 'Any Country',
  );

  const disableInputs = (...inputs) =>
    inputs.forEach((input) => input.setAttribute('disabled', true));
  const enableInputs = (...inputs) =>
    inputs.forEach((input) => input.removeAttribute('disabled'));

  configuratorPriceInput.addEventListener('change', (event) => {
    if (event.target.checked) {
      priceAmountInput.value = 0;
      strikethroughAmountInput.value = 0;
      strikethroughAmountEnabledInput.checked = false;
      anyCountryOption.selected = true;

      disableInputs(
        priceAmountInput,
        strikethroughAmountInput,
        strikethroughAmountEnabledInput,
        priceCountryIsoInput,
      );
    } else {
      enableInputs(
        priceAmountInput,
        strikethroughAmountInput,
        strikethroughAmountEnabledInput,
        priceCountryIsoInput,
      );
    }
  });
});
